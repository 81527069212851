import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Common/Market/Airdrop.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/App/Spinner.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/LineGraph.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/App/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "col-span-4 flex items-center justify-start pl-4" };
const _hoisted_2 = { class: "flex items-center justify-start gap-2" };
const _hoisted_3 = { class: "flex items-center justify-start" };
const _hoisted_4 = { class: "text-left text-gray-700 text-sm whitespace-nowrap" };
const _hoisted_5 = { class: "flex leading-4 font-bold" };
const _hoisted_6 = { class: "text-gray-500 text-xs leading-3.5" };
const _hoisted_7 = { class: "w-full text-gray-900 font-medium text-sm font-mono text-right" };
const _hoisted_8 = { class: "flex align-center justify-end" };
const _hoisted_9 = {
  key: 2,
  class: "text-gray-400"
};
const _hoisted_10 = { class: "col-span-2 flex" };
const _hoisted_11 = {
  key: 1,
  class: "text-gray-400"
};
const _hoisted_12 = {
  key: 0,
  class: "col-span-2 align-center justify-self-end"
};
import { BigNumberInBase, Status, StatusType } from "@injectivelabs/utils";
import {
  MARKETS_HISTORY_CHART_ONE_HOUR,
  MARKETS_HISTORY_CHART_SEVEN_DAYS,
  UI_DEFAULT_PRICE_DISPLAY_DECIMALS
} from "@/app/utils/constants";
import { Change, TradeClickOrigin } from "@/types";
import {
  getMarketRoute,
  getFormattedMarketsHistoryChartData
} from "@/app/utils/market";
import { amplitudeTracker } from "@/app/providers/AmplitudeTracker";
export default /* @__PURE__ */ _defineComponent({
  __name: "MarketsRow",
  props: {
    isHero: Boolean,
    market: {
      required: true,
      type: Object
    },
    summary: {
      type: Object,
      default: void 0
    }
  },
  setup(__props) {
    var _a;
    const props = __props;
    const exchangeStore = useExchangeStore();
    const { $onError } = useNuxtApp();
    const status = reactive(new Status(StatusType.Loading));
    const useDefaultLastTradedPriceColor = ref(true);
    const lastTradedPriceTextColorClass = computed(() => {
      if (useDefaultLastTradedPriceColor) {
        return "text-gray-700";
      }
      return {
        "text-green-700": lastPriceChange.value !== Change.Decrease,
        "text-red-500": lastPriceChange.value === Change.Decrease
      };
    });
    const lastTradedPrice = computed(
      () => {
        var _a2;
        return new BigNumberInBase(((_a2 = props.summary) == null ? void 0 : _a2.price) || 0);
      }
    );
    const { valueToString: lastTradedPriceToFormat } = useBigNumberFormatter(
      lastTradedPrice,
      {
        decimalPlaces: ((_a = props.market) == null ? void 0 : _a.priceDecimals) || UI_DEFAULT_PRICE_DISPLAY_DECIMALS,
        displayAbsoluteDecimalPlace: true
      }
    );
    const change = computed(() => {
      var _a2;
      return new BigNumberInBase(((_a2 = props.summary) == null ? void 0 : _a2.change) || 0);
    });
    const { valueToString: changeToFormat } = useBigNumberFormatter(change, {
      decimalPlaces: 2,
      minimalDecimalPlaces: 4
    });
    const lastPriceChange = computed(() => {
      if (!props.market || !props.summary) {
        return Change.NoChange;
      }
      if (!props.summary.lastPriceChange) {
        return Change.NoChange;
      }
      return props.summary.lastPriceChange;
    });
    const chartData = computed(() => {
      if (exchangeStore.marketsHistory.length === 0 || !props.market) {
        return [];
      }
      const matchingMarket = exchangeStore.marketsHistory.find(
        (marketHistory) => {
          return marketHistory.marketId === props.market.marketId;
        }
      );
      if (!matchingMarket) {
        return [];
      }
      return getFormattedMarketsHistoryChartData(matchingMarket);
    });
    const chartIsPositive = computed(() => {
      const minimumChartDataPoints = 2;
      if (chartData.value.length < minimumChartDataPoints) {
        return "";
      }
      const [firstChartDataPoint] = chartData.value;
      const lastChartDataPointPosition = new BigNumberInBase(chartData.value.length).minus(1).toNumber();
      const [, firstYaxisHolcPrice] = firstChartDataPoint;
      const [, lastYAxisHolcPrice] = chartData.value[lastChartDataPointPosition];
      return new BigNumberInBase(lastYAxisHolcPrice).gte(firstYaxisHolcPrice);
    });
    const marketRoute = computed(() => {
      const marketRoute2 = getMarketRoute(props.market);
      return marketRoute2 || { name: "markets" };
    });
    watch(
      () => lastPriceChange,
      (status2) => {
        if (status2.value === Change.NoChange) {
          return;
        }
        updateLastPriceChangeColor();
      }
    );
    onMounted(() => {
      Promise.all([
        exchangeStore.getMarketsHistory({
          marketIds: [props.market.marketId],
          resolution: MARKETS_HISTORY_CHART_ONE_HOUR,
          countback: MARKETS_HISTORY_CHART_SEVEN_DAYS
        })
      ]).catch($onError).finally(() => {
        status.setIdle();
      });
    });
    function updateLastPriceChangeColor() {
      useDefaultLastTradedPriceColor.value = false;
      setTimeout(() => {
        useDefaultLastTradedPriceColor.value = true;
      }, 3e3);
    }
    function handleTradeClickedTrack() {
      amplitudeTracker.submitTradeClickedTrackEvent({
        market: props.market.slug,
        marketType: props.market.subType,
        origin: TradeClickOrigin.Lander
      });
    }
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      const _component_PartialsCommonMarketAirdrop = __nuxt_component_2;
      const _component_BaseIcon = __nuxt_component_3;
      const _component_AppSpinner = __nuxt_component_4;
      const _component_BaseLineGraph = __nuxt_component_5;
      const _component_AppButton = __nuxt_component_6;
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["block", { "min-w-3xl lg:min-w-[912px]": !__props.isHero }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["grid grid-cols-12 items-center py-4 box-content", { "gap-4": __props.isHero }]),
          onClick: handleTradeClickedTrack
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_NuxtLink, { to: _unref(marketRoute) }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    __props.market.baseToken ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
                      key: 0,
                      token: __props.market.baseToken,
                      class: "w-4 h-4 md:w-6 md:h-6 mr-3"
                    }, null, 8, ["token"])) : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, _toDisplayString(__props.market.ticker), 1),
                      _createElementVNode("p", _hoisted_6, _toDisplayString(__props.market.baseToken.name), 1)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["to"]),
              _createVNode(_component_PartialsCommonMarketAirdrop, { market: __props.market }, null, 8, ["market"])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["flex", { "col-span-2": !__props.isHero, "col-span-3": __props.isHero }])
          }, [
            _createElementVNode("span", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                !_unref(lastTradedPrice).isNaN() && !_unref(useDefaultLastTradedPriceColor) ? (_openBlock(), _createBlock(_component_BaseIcon, {
                  key: 0,
                  name: "arrow",
                  class: _normalizeClass(["transform w-3 h-3 mr-1 mt-1", {
                    "text-green-700 rotate-90": _unref(lastPriceChange) === _unref(Change).Increase,
                    "text-red-500 -rotate-90": _unref(lastPriceChange) === _unref(Change).Decrease
                  }])
                }, null, 8, ["class"])) : _createCommentVNode("", true),
                !_unref(lastTradedPrice).isNaN() ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass(_unref(lastTradedPriceTextColorClass))
                }, _toDisplayString(_unref(lastTradedPriceToFormat)), 3)) : (_openBlock(), _createElementBlock("span", _hoisted_9, "\u2014"))
              ])
            ])
          ], 2),
          _createElementVNode("div", _hoisted_10, [
            !_unref(change).isNaN() ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([[
                _unref(change).gte(0) ? "text-green-700" : "text-red-500",
                {
                  "text-right": !__props.isHero
                }
              ], "w-full font-mono text-sm"])
            }, _toDisplayString(_unref(changeToFormat)) + "% ", 3)) : (_openBlock(), _createElementBlock("span", _hoisted_11, "\u2014"))
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["flex h-7 w-[70%] relative", {
              "col-span-2 justify-self-center lg:justify-self-end": !__props.isHero,
              "col-span-3": __props.isHero
            }])
          }, [
            _unref(status).isLoading() ? (_openBlock(), _createBlock(_component_AppSpinner, {
              key: 0,
              md: ""
            })) : _createCommentVNode("", true),
            _unref(chartData).length > 1 ? (_openBlock(), _createBlock(_component_BaseLineGraph, {
              key: 1,
              data: _unref(chartData),
              color: _unref(chartIsPositive) ? "#0BB67D" : "#F3164D",
              "stroke-width": 1
            }, null, 8, ["data", "color"])) : _createCommentVNode("", true)
          ], 2),
          !__props.isHero ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_NuxtLink, { to: _unref(marketRoute) }, {
              default: _withCtx(() => [
                _createVNode(_component_AppButton, { class: "border-blue-500 text-blue-500 w-full" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("common.trade")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["to"])
          ])) : _createCommentVNode("", true)
        ], 2)
      ], 2);
    };
  }
});
