import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Input/Index.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/App/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "py-20 bg-gray-900 text-center" };
const _hoisted_2 = { class: "text-3xl font-semibold" };
const _hoisted_3 = { class: "max-w-[340px] xs:max-w-[360px] mt-6 mx-auto" };
const _hoisted_4 = { class: "bg-gray-750 rounded-lg flex items-center" };
const _hoisted_5 = { class: "text-sm" };
const _hoisted_6 = {
  key: 0,
  class: "mt-2 text-left text-sm capitalize-phrase"
};
const _hoisted_7 = { class: "text-red-500" };
const _hoisted_8 = { class: "mt-6" };
const _hoisted_9 = {
  class: "underline hover:text-blue-500",
  href: "https://injectivelabs.org/terms-and-conditions",
  target: "_blank"
};
const _hoisted_10 = {
  class: "underline hover:text-blue-500",
  href: "https://injectivelabs.org/privacy-policy",
  target: "_blank"
};
const _hoisted_11 = {
  class: "underline hover:text-blue-500",
  href: "https://injective.com/disclaimer/",
  target: "_blank"
};
import { Status, StatusType } from "@injectivelabs/utils";
import { subscribeToNewsletter } from "@/app/services/newsletter";
export default /* @__PURE__ */ _defineComponent({
  __name: "Newsletter",
  setup(__props) {
    const { t } = useLang();
    const { success, error } = useNotifications();
    const { handleSubmit, resetForm } = useForm();
    const status = reactive(new Status(StatusType.Idle));
    const { value, errors } = useStringField({
      name: "email",
      rule: "required|email"
    });
    const subscribe = handleSubmit((values) => {
      status.setLoading();
      subscribeToNewsletter(values.email).then(() => {
        success({
          title: t("newsletter.subscribeToast")
        });
        resetForm();
      }).catch((e) => {
        error(e.message.replace("Error", ""));
      }).finally(() => {
        status.setIdle();
      });
    });
    return (_ctx, _cache) => {
      const _component_AppInput = __nuxt_component_0;
      const _component_AppButton = __nuxt_component_1;
      const _component_i18n_t = _resolveComponent("i18n-t");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("newsletter.title")), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_AppInput, {
              modelValue: _unref(value),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(value) ? value.value = $event : null),
              placeholder: _ctx.$t("newsletter.emailAddress"),
              "transparent-bg": ""
            }, null, 8, ["modelValue", "placeholder"]),
            _createVNode(_component_AppButton, {
              xl: "",
              class: "bg-blue-500 text-blue-900",
              disabled: _unref(errors).length > 0,
              status: _unref(status),
              onClick: _unref(subscribe)
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("newsletter.subscribe")), 1)
              ]),
              _: 1
            }, 8, ["disabled", "status", "onClick"])
          ]),
          _unref(errors).length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(errors)[0]), 1)
          ])) : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_i18n_t, {
              keypath: "newsletter.disclaimerMessage",
              tag: "p",
              class: "text-xs text-gray-450"
            }, {
              termsAndCondition: _withCtx(() => [
                _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.$t("newsletter.termsAndCondition")), 1)
              ]),
              privacyPolicy: _withCtx(() => [
                _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.$t("newsletter.privacyPolicy")), 1)
              ]),
              disclaimer: _withCtx(() => [
                _createElementVNode("a", _hoisted_11, _toDisplayString(_ctx.$t("newsletter.disclaimer")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]);
    };
  }
});
