"use strict";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "bg-white" };
const _hoisted_2 = { class: "h-full w-full flex flex-wrap pb-4" };
const _hoisted_3 = { class: "container" };
const _hoisted_4 = { class: "w-full mx-auto xl:w-4/5" };
export function render(_ctx, _cache) {
  const _component_AssetNoticeBanners = _resolveComponent("AssetNoticeBanners");
  const _component_AssetKadoBanner = _resolveComponent("AssetKadoBanner");
  const _component_PartialsHomeHero = _resolveComponent("PartialsHomeHero");
  const _component_PartialsHomeMarkets = _resolveComponent("PartialsHomeMarkets");
  const _component_PartialsHomeWhyTrade = _resolveComponent("PartialsHomeWhyTrade");
  const _component_PartialsHomeGettingStarted = _resolveComponent("PartialsHomeGettingStarted");
  const _component_PartialsHomeFooterCta = _resolveComponent("PartialsHomeFooterCta");
  return _openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AssetNoticeBanners),
    _createVNode(_component_AssetKadoBanner),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PartialsHomeHero),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_PartialsHomeMarkets, { class: "mt-8 sm:mt-16" }),
            _createVNode(_component_PartialsHomeWhyTrade),
            _createVNode(_component_PartialsHomeGettingStarted)
          ])
        ])
      ]),
      _createVNode(_component_PartialsHomeFooterCta)
    ])
  ]);
}
