import { default as __nuxt_component_0 } from "/opt/build/repo/components/Asset/LogoMini.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Asset/LogoText1.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/App/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col sm:block" };
const _hoisted_2 = { class: "flex items-center justify-start mb-8" };
const _hoisted_3 = { class: "text-gray-900 font-bold text-[36px] leading-[58px] sm:text-5xl sm:leading-[60px] 3xl:leading-[72px] 3xl:text-[60px] 4xl:leading-[77px] 4xl:text-[64px] mb-8" };
const _hoisted_4 = { class: "text-gray-900 mt-4 text-base 3xl:text-xl mb-8" };
const _hoisted_5 = { class: "grid grid-cols-12 gap-4 text-base 3xl:text-xl" };
const _hoisted_6 = { class: "col-span-6 flex items-center justify-start" };
const _hoisted_7 = { class: "text-gray-900 text-lg" };
const _hoisted_8 = { class: "col-span-6 flex items-center justify-start" };
const _hoisted_9 = { class: "text-gray-900 text-lg" };
const _hoisted_10 = { class: "col-span-6 flex items-center justify-start" };
const _hoisted_11 = { class: "text-gray-900 text-lg" };
const _hoisted_12 = { class: "col-span-6 flex items-center justify-start" };
const _hoisted_13 = { class: "text-gray-900 text-lg" };
import { MarketType } from "@injectivelabs/sdk-ui-ts";
import { amplitudeTracker } from "@/app/providers/AmplitudeTracker";
import { getDefaultPerpetualMarketRouteParams } from "@/app/utils/market";
import { DefaultMarket, TradeClickOrigin, Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Cta",
  setup(__props) {
    const router = useRouter();
    const modalStore = useModalStore();
    const walletStore = useWalletStore();
    function handleGetStartedClick() {
      handleTradeClickedTrack();
      if (walletStore.isUserWalletConnected) {
        router.push(getDefaultPerpetualMarketRouteParams());
      } else {
        modalStore.openModal({ type: Modal.Connect });
      }
    }
    function handleTradeClickedTrack() {
      amplitudeTracker.submitTradeClickedTrackEvent({
        market: DefaultMarket.Perpetual,
        marketType: MarketType.Perpetual,
        origin: TradeClickOrigin.Lander
      });
    }
    return (_ctx, _cache) => {
      const _component_AssetLogoMini = __nuxt_component_0;
      const _component_AssetLogoText1 = __nuxt_component_1;
      const _component_BaseIcon = __nuxt_component_2;
      const _component_AppButton = __nuxt_component_3;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AssetLogoMini, {
            class: "h-10 w-10 mr-2",
            alt: "Parallel"
          }),
          _createVNode(_component_AssetLogoText1, { class: "block h-6 text-gray-900" })
        ]),
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("home.title")), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("home.subtitle")), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_BaseIcon, {
              name: "lightning-fill",
              class: "text-gray-900 w-6 h-6 mr-3"
            }),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("home.fast")), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_BaseIcon, {
              name: "bounding-box-circles",
              class: "text-gray-900 w-6 h-6 mr-3"
            }),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("home.interoperable")), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_BaseIcon, {
              name: "gas",
              class: "text-gray-900 w-6 h-6 mr-3"
            }),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("home.gasFree")), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_BaseIcon, {
              name: "shield-lock-fill",
              class: "text-gray-900 w-6 h-6 mr-3"
            }),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("home.secure")), 1)
          ])
        ]),
        _createVNode(_component_AppButton, {
          lg: "",
          class: "bg-gray-750 text-white mt-8 font-semibold",
          onClick: handleGetStartedClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("home.startTradingNow")), 1)
          ]),
          _: 1
        })
      ]);
    };
  }
});
