import { default as __nuxt_component_0 } from "/opt/build/repo/components/Asset/NoticeBanners/Banner.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { key: 0 };
import { VITE_BANNER_NOTICE_ENABLED } from "@/app/utils/constants/setup";
import { NoticeBanner } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  setup(__props) {
    const appStore = useAppStore();
    const banners = [
      {
        key: NoticeBanner.sheduledUpgradeMarch,
        label: `banners.banner-${NoticeBanner.sheduledUpgradeMarch}`
      }
    ];
    const filteredBanners = computed(
      () => banners.filter(
        (banner) => !appStore.userState.bannersViewed.includes(banner.key)
      )
    );
    return (_ctx, _cache) => {
      const _component_AssetNoticeBannersBanner = __nuxt_component_0;
      return _unref(VITE_BANNER_NOTICE_ENABLED) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredBanners), (banner) => {
          return _openBlock(), _createBlock(_component_AssetNoticeBannersBanner, _mergeProps({
            key: `banner-${banner.key}`
          }, { noticeBanner: banner }), null, 16);
        }), 128))
      ])) : _createCommentVNode("", true);
    };
  }
});
