import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Home/Newsletter.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "w-full mx-auto max-w-xs sm:max-w-md md:max-w-full xl:w-4/5 flex flex-col md:block items-center" };
const _hoisted_2 = { class: "text-2xl md:text-4xl 4xl:text-5xl font-bold tracking-wide text-center" };
const _hoisted_3 = { class: "text-center" };
import { MarketType } from "@injectivelabs/sdk-ui-ts";
import { amplitudeTracker } from "@/app/providers/AmplitudeTracker";
import { getDefaultPerpetualMarketRouteParams } from "@/app/utils/market";
import { DefaultMarket, TradeClickOrigin, Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "FooterCta",
  setup(__props) {
    const router = useRouter();
    const modalStore = useModalStore();
    const walletStore = useWalletStore();
    function handleGetStartedClick() {
      handleTradeClickedTrack();
      if (walletStore.isUserWalletConnected) {
        router.push(getDefaultPerpetualMarketRouteParams());
      } else {
        modalStore.openModal({ type: Modal.Connect });
      }
    }
    function handleTradeClickedTrack() {
      amplitudeTracker.submitTradeClickedTrackEvent({
        market: DefaultMarket.Perpetual,
        marketType: MarketType.Perpetual,
        origin: TradeClickOrigin.Lander
      });
    }
    return (_ctx, _cache) => {
      const _component_AppButton = __nuxt_component_0;
      const _component_PartialsHomeNewsletter = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", {
          style: _normalizeStyle({ backgroundImage: `url('/svg/5166956.svg')` }),
          class: "bg-cover py-14 bg-left-top h-[189px] md:h-[790px] flex items-center"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("home.startTradingNote")), 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppButton, {
                lg: "",
                class: "mt-2 md:mt-10 bg-blue-500 text-blue-900 hover:bg-blue-600 hover:bg-opacity-100",
                onClick: handleGetStartedClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("home.tradeNow")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ], 4),
        _createVNode(_component_PartialsHomeNewsletter)
      ]);
    };
  }
});
