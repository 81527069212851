import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Select/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Home/Common/Markets.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Panel/Light.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "flex items-center justify-center sm:my-2 -mx-2 font-semibold gap-4 sm:gap-6" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { key: 1 };
import { MarketFilterType } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  setup(__props) {
    const FilterList = [MarketFilterType.Volume, MarketFilterType.New];
    const activeType = ref(MarketFilterType.Volume);
    return (_ctx, _cache) => {
      const _component_AppSelectButton = __nuxt_component_0;
      const _component_PartialsHomeCommonMarkets = __nuxt_component_1;
      const _component_AppPanelLight = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_AppPanelLight, null, {
        context: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(FilterList), (filterType) => {
              return _openBlock(), _createBlock(_component_AppSelectButton, {
                key: `market-filter-type-${filterType}`,
                modelValue: _unref(activeType),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(activeType) ? activeType.value = $event : null),
                value: filterType
              }, {
                default: _withCtx(({ active }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["text-xl text-gray-775 hover:opacity-100", [active ? "opacity-100" : " opacity-30"]])
                  }, [
                    filterType === _unref(MarketFilterType).Volume ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("home.trending")), 1)) : _createCommentVNode("", true),
                    filterType === _unref(MarketFilterType).New ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("home.newlyAdded")), 1)) : _createCommentVNode("", true)
                  ], 2)
                ]),
                _: 2
              }, 1032, ["modelValue", "value"]);
            }), 128))
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_PartialsHomeCommonMarkets, {
            "filter-type": _unref(activeType),
            limit: 10
          }, null, 8, ["filter-type"])
        ]),
        _: 1
      });
    };
  }
});
