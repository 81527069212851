import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Icon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "h-14 w-full bg-[#7e38b7] flex items-center justify-center gap-2" };
const _hoisted_2 = { class: "text-sm md:text-lg" };
const _hoisted_3 = { class: "flex items-center justify-start gap-1" };
const _hoisted_4 = { class: "text-sm md:text-lg text-93, 41, 135-500 font-medium" };
export default /* @__PURE__ */ _defineComponent({
  __name: "KadoBanner",
  setup(__props) {
    const walletStore = useWalletStore();
    const url = computed(() => {
      const suffix = walletStore.isUserWalletConnected ? `&onToAddress=${walletStore.injectiveAddress}` : "";
      return `https://app.kado.money/ramp?product=BUY&onPayCurrency=USD&onRevCurrency=USDT&offPayCurrency=USDC&offRevCurrency=USD&network=INJECTIVE${suffix}`;
    });
    return (_ctx, _cache) => {
      const _component_BaseIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("banners.kado.title")), 1),
        _createVNode(_component_NuxtLink, {
          to: _unref(url),
          target: "_blank"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("banners.kado.tryNow")), 1),
              _createVNode(_component_BaseIcon, {
                name: "arrow",
                class: "rotate-135 w-3 h-3 text-93, 41, 135-500"
              })
            ])
          ]),
          _: 1
        }, 8, ["to"])
      ]);
    };
  }
});
