import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Home/Common/MarketsHeader.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Home/Common/MarketsRow.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-3c515644"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "bg-white rounded-lg w-full self-center" };
const _hoisted_2 = { class: "overflow-auto" };
import { BigNumberInBase } from "@injectivelabs/utils";
import {
  MarketFilterType
} from "@/types";
import { deprecatedMarkets, newMarketsSlug } from "@/app/data/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Markets",
  props: {
    isHero: Boolean,
    limit: {
      type: Number,
      default: 3
    },
    filterType: {
      type: String,
      default: MarketFilterType.Volume
    }
  },
  setup(__props) {
    const props = __props;
    const appStore = useAppStore();
    const spotStore = useSpotStore();
    const derivativeStore = useDerivativeStore();
    const exchangeStore = useExchangeStore();
    const markets = computed(() => [
      ...derivativeStore.markets,
      ...spotStore.markets,
      ...exchangeStore.upcomingMarkets
    ]);
    const marketsWithSummary = computed(() => [
      ...derivativeStore.marketsWithSummary,
      ...exchangeStore.upcomingMarketsWithSummary,
      ...spotStore.marketsWithSummary
    ]);
    const newMarketsList = computed(() => {
      return marketsWithSummary.value.filter((summary) => {
        return newMarketsSlug.includes(summary.market.slug.toLowerCase());
      }).sort(
        (a, b) => newMarketsSlug.indexOf(a.market.slug) - newMarketsSlug.indexOf(b.market.slug)
      );
    });
    const filteredMarketsList = computed(() => {
      const slugs = [...exchangeStore.upcomingMarkets, deprecatedMarkets].map(
        (market) => market.slug
      );
      const filteredMarkets = marketsWithSummary.value.filter(
        (m) => !slugs.includes(m.market.slug)
      );
      if (props.filterType === MarketFilterType.New) {
        return newMarketsList.value;
      }
      if (props.filterType === MarketFilterType.Volume) {
        return filteredMarkets.sort(
          (a, b) => {
            var _a, _b;
            const aVolume = ((_a = a.summary) == null ? void 0 : _a.volume) || "0";
            const bVolume = ((_b = b.summary) == null ? void 0 : _b.volume) || "0";
            return new BigNumberInBase(bVolume).minus(aVolume).toNumber();
          }
        );
      }
      return filteredMarkets;
    });
    const marketsList = computed(
      () => filteredMarketsList.value.slice(0, props.limit)
    );
    const heroMarketsList = computed(() => {
      const [latestMarket, secondLatestMarket] = newMarketsList.value;
      if (!latestMarket) {
        return marketsList.value;
      }
      return secondLatestMarket ? [...marketsList.value, latestMarket, secondLatestMarket] : [...marketsList.value, latestMarket];
    });
    const categorizedMarketsList = computed(
      () => props.isHero ? heroMarketsList.value : marketsList.value
    );
    useIntervalFn(() => appStore.pollMarkets(), 5 * 1e3);
    return (_ctx, _cache) => {
      const _component_PartialsHomeCommonMarketsHeader = __nuxt_component_0;
      const _component_PartialsHomeCommonMarketsRow = __nuxt_component_1;
      const _component_AppHocLoading = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_AppHocLoading, {
        "show-loading": _unref(markets).length === 0
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_PartialsHomeCommonMarketsHeader, {
                class: "pt-6 pb-2",
                "is-hero": __props.isHero
              }, null, 8, ["is-hero"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categorizedMarketsList), ({ market, summary }) => {
                return _openBlock(), _createBlock(_component_PartialsHomeCommonMarketsRow, {
                  key: `market-${market.marketId}`,
                  market,
                  summary,
                  "is-hero": __props.isHero,
                  class: "border-b border-gray-300 last-of-type:border-b-0"
                }, null, 8, ["market", "summary", "is-hero"]);
              }), 128))
            ])
          ])
        ]),
        _: 1
      }, 8, ["show-loading"]);
    };
  }
});
