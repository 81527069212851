import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex justify-center items-center bg-blue-500 text-blue-900 p-1 border-b" };
const _hoisted_2 = { class: "font-semibold text-xs md:text-md flex-1 text-center" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Banner",
  props: {
    noticeBanner: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const appStore = useAppStore();
    function closeNoticeBanner() {
      appStore.setUserState({
        ...appStore.userState,
        bannersViewed: [...appStore.userState.bannersViewed, props.noticeBanner.key]
      });
    }
    return (_ctx, _cache) => {
      const _component_NuxtLink = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t(__props.noticeBanner.label)) + " ", 1),
          __props.noticeBanner.viewMore ? (_openBlock(), _createBlock(_component_NuxtLink, {
            key: 0,
            class: "underline",
            to: __props.noticeBanner.viewMoreLink,
            target: "_blank",
            rel: "noreferrer"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.noticeBanner.viewMore), 1)
            ]),
            _: 1
          }, 8, ["to"])) : _createCommentVNode("", true)
        ]),
        _createElementVNode("button", {
          class: "text-3xl font-bold p-2 leading-[0.5]",
          onClick: closeNoticeBanner
        }, " \xD7 ")
      ]);
    };
  }
});
