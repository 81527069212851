"use strict";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "container py-4 sm:py-10 xl:px-10 relative" };
const _hoisted_2 = { class: "w-full mx-auto" };
const _hoisted_3 = { class: "block md:grid rounded-[20px] p-6 md:p-16 lg:px-6 lg:py-8 2xl:px-20 2xl:py-16 bg-gray-100 bg-cover grid-cols-12 gap-12 items-center bg-hero" };
const _hoisted_4 = { class: "col-span-6 3xl:col-span-6 hidden lg:block lg:mt-6" };
const _hoisted_5 = { class: "col-span-3 flex gap-2 w-max items-center mt-6 md:mt-0" };
const _hoisted_6 = { class: "text-#6600cc text-sm md:text-base" };
export function render(_ctx, _cache) {
  const _component_PartialsHomeHeroCta = _resolveComponent("PartialsHomeHeroCta");
  const _component_PartialsHomeCommonMarkets = _resolveComponent("PartialsHomeCommonMarkets");
  const _component_AssetInjective = _resolveComponent("AssetInjective");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PartialsHomeHeroCta, { class: "col-span-12 lg:col-span-6 3xl:col-span-6" }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_PartialsHomeCommonMarkets, {
            "is-hero": "",
            class: "max-w-lg ml-auto 3xl:mr-10"
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("home.builtOn")), 1),
          _createVNode(_component_AssetInjective, { class: "w-20 md:w-28" })
        ])
      ])
    ])
  ]);
}
